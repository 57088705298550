.maps-root {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.maps-root > div:first-child {
  flex: 0 0 20%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  height: 100%;
}

.maps-root > div:last-child {
  flex: 1 1 auto;
}

.mappart-base {
  a {
    color: var(--purple);
  }

  a.active {
    color: var(--cyan);
  }

  a.active:before {
    content: "> ";
  }
}

.quest-link {
  color: var(--cyan);
  .Mui-selected {
    color: var(--red);
  }
}

.quest-step {
  opacity: 1 !important;
}

.quest-category {
  background-color: color-mix(in srgb, var(--dark-gray), transparent 70%);
}
