.about {
  color: var(--very-light-gray);
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 30%;
  max-width: 768px;

  a {
    color: var(--cyan);
  }

  a:hover {
    color: var(--green);
  }

  a:visited {
    color: var(--purple);
  }
}
