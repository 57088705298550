.content {
    display: flex;
    gap: 20px;
    padding: 20px;
}

.party-list {
    width: 400px;
}

.skillchain-results {
    flex-grow: 1;
    border: 1px solid grey;
    border-radius: 10px;
}

.party-member {
    margin-top: 15px;
    width: 100%;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.close-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 1.2em;
    cursor: pointer;
    padding: 5px;
    z-index: 2;
}

.skillchain-display {
    padding: 20px;
}

.skillchain-header {
    margin-bottom: 20px !important;
    font-size: 1.2em;
}

.weapon-select {
    margin-right: 30px !important;
}

.ws-display, .sc-display {
    width: 150px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
}

.skillchain-set  {
    margin-bottom: 10px;
}

.party-member > .name {
    font-size: 1.3em;
    font-weight: bold;
}

.ws-toggle {
    margin-left: 0px !important;
    display: block;
    width: 45%;
}

.ws-toggle span {
    font-size: 0.75em;
}

.ws-checkbox {
    padding: 0 5px !important;
}

.ws-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
}

.attribute-icon {
    border: 1px solid var(--almost-white);
    border-radius: 8px;
    margin-right: 10px;
    position: relative;
    top: 3px;
    right: 5px;
    box-shadow: 0 0 5px var(--very-light-gray);
}

.arrow-right {
    height: 16px;
    margin: 0 5px;
    fill: var(--almost-white);
}

.add-member {
    cursor: pointer;
    transition: all 300ms;
    border-width: 2px;
    border-color: var(--cyan);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--cyan);
    margin-top: 0;
}

.add-member:hover {
    transform: scale(1.03);
}
