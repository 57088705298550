#root, html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  background-color: var(--very-dark-gray) !important;
  cursor: unset !important;
}

.MuiAppBar-root {
  background-image: none !important;
}
