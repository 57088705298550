:root {
    --black: #1d1f21;
    --very-dark-gray: #282a2e;
    --dark-gray: #373b41;
    --gray: #969896;
    --light-gray: #b4b7b4;
    --very-light-gray: #c5c8c6;
    --almost-white: #e0e0e0;
    --white: #ffffff;

    --red: #cc6666;
    --orange: #de935f;
    --yellow: #f0c674;
    --green: #b5bd68;
    --cyan: #8abeb7;
    --blue: #81a2be;
    --purple: #b294bb;
    --brown: #a3685a;
}
